import _ from 'lodash';
import { getCurrentDomain } from 'common/currentDomain';
import { currentUserHasRight } from 'common/current_user';
import FeatureFlags from 'common/feature_flags';
import {
  AssetSearchOptions,
  blob,
  BrowseConfig,
  calendars,
  CatalogConfig,
  Category,
  charts,
  CustomFacet,
  datasets,
  Federations,
  files,
  FilterQuery,
  filters,
  ForgeItemValues,
  forms,
  href,
  links,
  maps,
  measures,
  stories,
  story,
  tables,
  ThisSiteOnlyString
} from './types';
import DomainRights from 'common/types/domainRights';
import I18n from 'common/i18n';
import FacetSection, { FacetOption } from 'common/components/FacetSidebar/FacetSection';
import { Filter, FilterOption, FilterOptionChild } from 'accessibleBrowseFilters/types';
import React from 'react';
import AutocompleteField from 'accessibleBrowseFilters/components/AutocompleteField';
import { ForgeDivider } from '@tylertech/forge-react';

export const addChildCategoriesToListIfPresent = (
  filterOptions: FilterOption[],
  valueToMatch: string,
  willDisplayInFilterChips: boolean,
  resultArray: FilterQuery[],
  filterParam: string
) => {
  for (let i = 0; i < filterOptions.length; i++) {
    const thisOption = filterOptions[i];
    if (thisOption.value === valueToMatch) {
      if (thisOption.children) {
        thisOption.children.map((child) => {
          resultArray.push({
            queryParam: filterParam,
            paramValue: child.value,
            displayInFilterChips: willDisplayInFilterChips
          });
        });
      }
      break;
    }
  }
};

export const alphabetizeFilterList = (filterList: Filter[]): Filter[] => {
  // loop through filters, sorting each of their options alphabetically
  filterList.forEach((f) => {
    const sortedList = _.sortBy(f.options, [
      function (filter) {
        return filter.text.toLowerCase();
      }
    ]);

    f.options = sortedList;
  });

  return filterList;
};

/**
 * Sometimes the query we send to catalog is different than the query displayed in the URL. This is especially
 * true for the federations filter. In the URL, we display "federation_filter=<domain_id>". But when we query catalog,
 * we send "domains=<domain_cname>". So, we have to do some swapping around of values in order to display
 * what we want and pass to catalog the values we need.
 * @param optionText Provided by the DB, intended to be the text displayed next to the radio button for this filter option
 * @param optionValue Provided by the DB, intended to be the query value for the filter.
 * @returns
 */
export const assignForgeListItemValuesForFederationFilter = (
  optionText: string,
  optionValue: string
): ForgeItemValues => {
  const returnValues: ForgeItemValues = {
    forgeListItemText: optionText,
    forgeListItemValue: optionValue,
    urlValue: optionValue
  };

  if (optionText === ThisSiteOnlyString) {
    returnValues.forgeListItemValue = getCurrentDomain();
  } else {
    returnValues.forgeListItemValue = optionText;
  }

  return returnValues;
};

export const buildFacets = (results: BrowseConfig, view_types_facet?: string[]): Filter[] => {
  let filterFacets: Filter[] = [];

  if (FeatureFlags.value('show_provenance_facet_in_catalog')) {
    filterFacets.push(generateProvenanceFilter());
  }

  filterFacets.push(generateViewTypes(view_types_facet));

  if (results.categories) {
    filterFacets.push(createCategoryFilter(results.categories));
  }

  if (results.customFacets) {
    filterFacets = filterFacets.concat(createCustomFilters(results.customFacets));
  }

  if (results.federations) {
    filterFacets.push(createFederationFilter(results.federations));
  }

  filterFacets = alphabetizeFilterList(filterFacets);
  return filterFacets;
};

/** Look at the Filter information and generate specific FacetSection components
 * for each one.
 */
export const buildFacetSections = (
  filterList: Filter[],
  onFacetOptionSelect: (filterParam: string, filterValue: string) => void,
  onFacetClear: (filterParam: string) => void,
  defaultFederationFilter?: string
): React.ReactNode => {
  const facetSections: React.ReactNode[] = [];
  let selectedOption: string;

  filterList.map((filter) => {
    const optionsDisplayCount = filter.param === 'limitTo' ? 10000 : 5;
    const options = transformFilterOptionsToFacetOptions(filter);

    /** If we are looking at a federation facet AND there is a federation in the URL, use one specified in the URL.
     * Otherwise, use the default federation filter. If there is no default federation filter, there is no selected option */
    if (filter.param === 'federation_filter') {
      if (getFacetValuesFromUrl(filter.param)?.[0]) {
        selectedOption = getFacetValuesFromUrl(filter.param)?.[0];
      } else if (defaultFederationFilter && defaultFederationFilter != '') {
        selectedOption = defaultFederationFilter;
      } else {
        selectedOption = '';
      }
    } else {
      selectedOption = getFacetValuesFromUrl(filter.param)?.[0];
    }

    facetSections.push(
      <FacetSection
        options={options}
        title={filter.title}
        optionsDisplayCount={optionsDisplayCount}
        facetName={filter.param}
        onFacetOptionSelect={onFacetOptionSelect}
        onFacetClear={onFacetClear}
        key={filter.title}
        selectedFacetOption={selectedOption}
      />
    );
  });

  return facetSections;
};

/** FacetSidebar renders child React nodes. This looks at the data we got from the API call and
 * makes FacetSection components for each of the filters, and also creates the dropdown component
 * for selecting tags.
 */
export const buildFacetSidebarContent = (
  filterList: Filter[],
  onFacetOptionSelect: (filterParam: string, filterValue: string) => void,
  onFacetClear: (filterParam: string) => void,
  tags: Filter | undefined,
  onTagSelect: (tagValue: string) => void,
  onTagClear: () => void,
  defaultFederationFilter: string
): React.ReactNode => {
  return (
    <div>
      {buildFacetSections(filterList, onFacetOptionSelect, onFacetClear, defaultFederationFilter)}
      <ForgeDivider />
      {tags && (
        <AutocompleteField
          key={tags.param}
          fieldInfo={tags}
          isMobile={false}
          onAutocompleteSelect={onTagSelect}
          onClear={onTagClear}
        />
      )}
    </div>
  );
};

export const catalogConfigToSortBy = (catalogConfig: CatalogConfig): AssetSearchOptions => {
  const queryParams: AssetSearchOptions = {};
  if (catalogConfig.properties && catalogConfig.properties.length > 0) {
    catalogConfig.properties.map((property) => {
      if (property.name === 'sortBy') {
        queryParams.order = translateSortByToOrder(property.value as string);
      }
    });
  }
  return queryParams;
};

export const catalogConfigToFederationFilter = (catalogConfig: CatalogConfig): string => {
  let federation_filter = '';
  if (catalogConfig.properties && catalogConfig.properties.length > 0) {
    catalogConfig.properties.map((property) => {
      if (property.name === 'federation_filter') {
        federation_filter = property.value.toString();
      }
    });
  }
  return federation_filter;
};

export const createCustomFilters = (facets: CustomFacet[]): Filter[] => {
  const customFilters: Filter[] = [];
  facets.map((facet) => {
    const filterOptions: FilterOption[] = [];
    facet.options.map((option) => {
      const newOption: FilterOption = {
        text: option,
        value: option
      };
      filterOptions.push(newOption);
    });

    const newFilter: Filter = {
      title: facet.title,
      param: facet.param,
      options: filterOptions
    };

    customFilters.push(newFilter);
  });

  return customFilters;
};

export const createCategoryFilter = (categories: Category[]): Filter => {
  const DEFAULT_CATEGORIES = [
    'fun',
    'government',
    'personal',
    'education',
    'business',
    'finance',
    'health',
    'other'
  ];
  const scope = 'shared.metadata_template.default_categories';

  const filterOptions: FilterOption[] = [];

  categories.forEach((category) => {
    const displayText = _.includes(DEFAULT_CATEGORIES, category.value)
      ? I18n.t(category.value, { scope })
      : category.value;

    const newOption: FilterOption = {
      text: displayText,
      value: category.value,
      children: undefined
    };

    if (category.children) {
      const newOptionChildren: FilterOptionChild[] = [];

      category.children.forEach((child) => {
        const thisChild: FilterOptionChild = {
          text: child.value,
          value: child.value
        };

        newOptionChildren.push(thisChild);
      });

      const alphabetizedChildren = _.sortBy(newOptionChildren, [
        function (child) {
          return child.text.toLowerCase();
        }
      ]);

      newOption.children = alphabetizedChildren;
    }

    filterOptions.push(newOption);
  });

  const categoryFilter: Filter = {
    param: 'category',
    title: I18n.t('controls.browse.facets.categories_title'),
    options: filterOptions
  };

  return categoryFilter;
};

export const createTags = (tagList: string[]): Filter => {
  const tags: FilterOption[] = [];
  tagList.map((tag) => {
    const newFilterOption: FilterOption = {
      text: tag,
      value: tag
    };

    tags.push(newFilterOption);
  });

  const tagsFilter: Filter = {
    param: 'tags',
    title: I18n.t('controls.browse.facets.tags_title'),
    options: tags
  };
  return tagsFilter;
};

export const createFederationFilter = (federations: Federations): Filter => {
  const filterOptions: FilterOption[] = [];
  federations.options.map((option) => {
    let text: string = option.text;
    if (text === ThisSiteOnlyString) {
      text = I18n.t('controls.browse.facets.this_site_only');
    }

    const filterOption: FilterOption = {
      text: text,
      value: option.value.toString()
    };

    filterOptions.push(filterOption);
  });

  return {
    param: federations.param,
    title: I18n.t('controls.browse.browse3.filter.domain'),
    options: filterOptions
  };
};

// We require all special chars encoded except _ and -, noting that encodeURIComponent will not encode -_.!~*'()
export const encodeFeaturedContentPath = (path: string): string => {
  return encodeURIComponent(path)
    .replace(/[^A-Za-z0-9%_-]/g, (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`)
    .replace(/%20/g, '+');
};

export const formatViewTypesFacetValue = (facetValue: Array<string> | string): Array<string> => {
  if (Array.isArray(facetValue)) {
    return facetValue;
  } else if (typeof facetValue === 'string' && facetValue !== '') {
    const result = facetValue.split(',').map((v) => {
      const firstTrim = v.trim(); // remove whitespace
      const secondTrim = _.trim(firstTrim, '"'); // remove any quotation marks
      return secondTrim;
    });
    return result;
  } else {
    return [];
  }
};

export const generateProvenanceFilter = () => {
  const scope = 'controls.browse.browse3.filter';

  const officialFilterOption: FilterOption = {
    text: I18n.t('authority.official', { scope }),
    value: 'official'
  };

  const communityFilterOption: FilterOption = {
    text: I18n.t('authority.community', { scope }),
    value: 'community'
  };

  const authorityFilter: Filter = {
    title: I18n.t('authority.title', { scope }),
    param: 'provenance',
    options: [officialFilterOption, communityFilterOption]
  };

  return authorityFilter;
};

export const generateViewTypes = (view_types_facet?: string[]): Filter => {
  const scope = 'controls.browse.facets.view_types';

  const datasetsFilter: FilterOption = {
    text: I18n.t(datasets, { scope }),
    value: datasets
  };

  const chartsFilter: FilterOption = {
    text: I18n.t(charts, { scope }),
    value: charts
  };

  const mapsFilter: FilterOption = {
    text: I18n.t(maps, { scope }),
    value: maps
  };

  const calendarsFilter: FilterOption = {
    text: I18n.t(calendars, { scope }),
    value: calendars
  };

  const filtersFilter: FilterOption = {
    text: I18n.t(filters, { scope }),
    value: filters
  };

  const hrefFilter: FilterOption = {
    text: I18n.t(href, { scope }),
    value: href
  };

  const blobFilter: FilterOption = {
    text: I18n.t(blob, { scope }),
    value: blob
  };

  const formsFilter: FilterOption = {
    text: I18n.t(forms, { scope }),
    value: forms
  };

  const storiesFilter: FilterOption = {
    text: I18n.t(story, { scope }),
    value: story
  };

  const measuresFilter: FilterOption = {
    text: I18n.t(measures, { scope }),
    value: measures
  };

  const createStandardViewTypes = (): Filter => {
    const viewTypesFilter: Filter = {
      title: I18n.t('controls.browse.facets.view_types_title'),
      param: 'limitTo',
      options: [
        datasetsFilter,
        chartsFilter,
        mapsFilter,
        calendarsFilter,
        filtersFilter,
        hrefFilter,
        blobFilter,
        formsFilter
      ]
    };

    if (FeatureFlags.value('stories_show_facet_in_catalog')) {
      viewTypesFilter.options.push(storiesFilter);
    }

    if (FeatureFlags.value('open_performance_standalone_measures')) {
      viewTypesFilter.options.push(measuresFilter);
    }

    return viewTypesFilter;
  };

  const createViewTypesFromConfig = (view_types: string[]): Filter => {
    const viewTypesFilter: Filter = {
      title: I18n.t('controls.browse.facets.view_types_title'),
      param: 'limitTo',
      options: []
    };

    for (const facet of view_types) {
      switch (facet) {
        case datasets:
          viewTypesFilter.options.push(datasetsFilter);
          break;
        case charts:
          viewTypesFilter.options.push(chartsFilter);
          break;
        case maps:
          viewTypesFilter.options.push(mapsFilter);
          break;
        case calendars:
          viewTypesFilter.options.push(calendarsFilter);
          break;
        case filters:
          viewTypesFilter.options.push(filtersFilter);
          break;
        case href:
          viewTypesFilter.options.push(hrefFilter);
          break;
        case blob:
          viewTypesFilter.options.push(blobFilter);
          break;
        case forms:
          viewTypesFilter.options.push(formsFilter);
          break;
        case story:
          if (FeatureFlags.value('stories_show_facet_in_catalog')) {
            viewTypesFilter.options.push(storiesFilter);
          }
          break;
        case measures:
          if (FeatureFlags.value('open_performance_standalone_measures')) {
            viewTypesFilter.options.push(measuresFilter);
          }
          break;
        default:
          break;
      }
    }

    return viewTypesFilter;
  };

  if (view_types_facet && view_types_facet.length > 0) {
    return createViewTypesFromConfig(view_types_facet);
  } else {
    return createStandardViewTypes();
  }
};

export const getAudienceForUser = (): string[] => {
  if (
    FeatureFlags.value('data_catalog_audience_level') === 'internal' &&
    (currentUserHasRight(DomainRights.can_view_internal_data) ||
      (currentUserHasRight(DomainRights.can_read_metadata) &&
        FeatureFlags.value('enable_can_read_metadata_domain_right')))
  ) {
    return ['public', 'site'];
  } else {
    return ['public'];
  }
};

/** Given a parameter, get the value it has in the URL. This will return all
 * values if there are multiple.
 */
export const getFacetValuesFromUrl = (facetParam: string): string[] => {
  const currentURL: string = window.location.href;
  const searchParams = new URL(currentURL).searchParams;
  return searchParams.getAll(facetParam);
};

export const getFeaturedContentKey = (filterParams: FilterQuery[], customFacets: CustomFacet[]) => {
  // if no filter params, use '/browse3' as the key
  const customFacetParams = customFacets ? customFacets.map((facet) => facet.param) : [];
  // NOTE: despite the .map and join('&') calls below, we only allow featured content for a single param.
  // See: https://support.socrata.com/hc/en-us/articles/115005683108-Featured-Content-for-the-Data-Catalog
  const featuredContentKey = filterParams
    .map((param) => {
      if (param.queryParam === 'only')
        return encodeFeaturedContentPath(
          'limitTo=' + translateDisplayTypeFromCeteraToUrlFormat(param.paramValue)
        );
      else if (param.queryParam === 'categories')
        return encodeFeaturedContentPath('category=' + param.paramValue);
      else if (customFacetParams.includes(param.queryParam))
        return param.queryParam + encodeFeaturedContentPath('=' + param.paramValue);
      else if (param.queryParam === 'provenance' || param.queryParam === 'federation_filter')
        return encodeFeaturedContentPath(param.queryParam + '=' + param.paramValue);
    })
    .sort()
    .join('&');
  if (featuredContentKey === '') return encodeURIComponent('/browse');
  return featuredContentKey;
};

export const getParamValueInUrl = (param: string): string | null => {
  const currentURL: string = window.location.href;
  const searchParams = new URL(currentURL).searchParams;
  return searchParams.get(param);
};

/** SortBy Precedence: if the sort order is provided in the URL, let's use that. Otherwise, choose the
 * default sort order provided from the catalog config. If that's not available, default to relevance.
 */
export const getSortBy = (catalogConfigOrder: string): string => {
  const sortByInUrl = getParamValueInUrl('sortBy') ?? '';
  if (sortByInUrl != '') {
    // the possible "sortBy" values in the URL are different than the possible "order" parameters used in the
    //  catalog API. We translate between the two here.
    return translateSortByToOrder(sortByInUrl);
  } else {
    if (catalogConfigOrder) {
      return catalogConfigOrder;
    } else {
      return 'relevance';
    }
  }
};

/** This is using for-loops because we can have a huge amount of custom categories and need to break ASAP */
export const getTextOfGivenCategory = (
  categoryValue: string,
  filterOptions: FilterOption[] | undefined
): string => {
  let result = '';

  if (filterOptions) {
    for (let i = 0; i < filterOptions.length; i++) {
      const thisOption = filterOptions[i];
      if (thisOption.value === categoryValue) {
        result = thisOption.text;
        break;
      } else if (thisOption.children) {
        for (let j = 0; j < thisOption.children.length; j++) {
          if (thisOption.children[j].value === categoryValue) {
            result = thisOption.children[j].text;
            break;
          }
        }
      }
    }
  }

  return result;
};

export const isSearchParamInUrl = (param: string, value: string): boolean => {
  const currentURL: string = window.location.href;
  const searchParams = new URL(currentURL).searchParams;
  const results = searchParams.getAll(param); // use getAll with query param name, and then check results for actual param value
  return results.includes(value);
};

export const removeQueryStringFromUrlNoReload = (filterParam: string) => {
  const currentURL: string = window.location.href;
  const url = new URL(currentURL);
  if (url.searchParams.get(filterParam)) {
    url.searchParams.delete(filterParam);
  }
  history.pushState({}, '', url.href);
};

/**
 * Some of the category options have children. We will display children that are one level deep.
 * To do this, we loop through each option in categories and build up a new filter containing
 * options for each of the options and their first  level children.
 * @param filterFacets All filters on the page
 * @returns
 */
export const restructureCategories = (filterFacets: Filter[]): Filter[] => {
  let categoryFilter: Filter;
  const isCategory = (filter: Filter) => filter.param === 'category';
  const index = filterFacets.findIndex(isCategory);

  if (index != -1) {
    categoryFilter = filterFacets[index];

    const newCategoryFilter: Filter = {
      param: categoryFilter.param,
      title: categoryFilter.title,
      options: []
    };

    categoryFilter.options.map((option) => {
      newCategoryFilter.options.push(option);
      if (option.children && option.children.length > 0) {
        option.children.map((c) => {
          const newOption: FilterOption = {
            text: c.text,
            value: c.value
          };
          newCategoryFilter.options.push(newOption);
        });
      }
    });

    if (categoryFilter.extra_options) {
      categoryFilter.extra_options.map((option) => {
        newCategoryFilter.options.push(option);
        if (option.children && option.children.length > 0) {
          option.children.map((c) => {
            const newOption: FilterOption = {
              text: c.text,
              value: c.value
            };
            newCategoryFilter.options.push(newOption);
          });
        }
      });
    }

    filterFacets[index] = newCategoryFilter;
  }

  return filterFacets;
};

export const transformFilterOptionsToFacetOptions = (filter: Filter): FacetOption[] => {
  const facetOptions: FacetOption[] = [];
  filter.options.map((option) => {
    const children: Omit<FacetOption, 'nestedChildren'>[] = [];
    if (option.children?.length) {
      option.children.map((child) => {
        children.push({
          text: child.text,
          value: child.value
        });
      });
    }

    facetOptions.push({
      text: option.text,
      value: option.value,
      nestedChildren: children
    });
  });

  return facetOptions;
};

// cetera needs the 'order' param passed in a different format than what we configure as 'sortBy' in the internal catalog config
export const translateSortByToOrder = (sortBy: string): string => {
  switch (sortBy) {
    case 'relevance':
      return 'relevance';
    case 'most_accessed':
      return 'page_views_total';
    case 'alpha':
      return 'name';
    case 'alpha_reversed':
      return 'name DESC';
    case 'newest':
      return 'createdAt';
    case 'oldest':
      return 'createdAt ASC';
    case 'last_modified':
      return 'updatedAt';
    case 'date':
      return 'createdAt';
    case 'name':
      return 'name';
    default:
      return 'relevance';
  }
};

export const translateParamToCeteraQueryParam = (param: string): string => {
  switch (param) {
    case 'domain_boosts':
      return 'boostDomains';
    case 'limitTo':
      return 'only';
    case 'datasetView':
      return 'only';
    case 'sortBy':
      return 'order';
    case 'default_sort':
      return 'order';
    case 'category':
      return 'categories';
    case 'federation_filter':
      return 'domains';
    default:
      return param;
  }
};

export const translateCeteraQueryParamToUrlParam = (param: string): string => {
  switch (param) {
    case 'boostDomains':
      return 'domain_boosts';
    case 'only':
      return 'limitTo';
    case 'order':
      return 'sortBy';
    case 'categories':
      return 'category';
    case 'domains':
      return 'federation_filter';
    default:
      return param;
  }
};

export const translateDisplayTypeFromUrlToCeteraFormat = (displayType: string) => {
  switch (displayType) {
    case story:
      return stories;
    case tables:
      return datasets;
    case blob:
      return files;
    case href:
      return links;
    default:
      return displayType;
  }
};

export const translateDisplayTypeFromCeteraToUrlFormat = (displayType: string) => {
  switch (displayType) {
    case stories:
      return story;
    case files:
      return blob;
    case links:
      return href;
    default:
      return displayType;
  }
};

// If there are already filters applied, ensure the value is updated and that we keep the remaining existing filter parameters.
//  Otherwise, add the filter and its value.
export const updateOrAddFilter = (
  filterQueries: FilterQuery[],
  translatedFilterParam: string,
  translatedFilterValue: string
): FilterQuery[] => {
  const newFilterQueries: FilterQuery[] = [];
  let found = false;

  filterQueries.forEach((filterQuery) => {
    if (filterQuery.queryParam === translatedFilterParam) {
      // push the updated value
      newFilterQueries.push({
        queryParam: translatedFilterParam,
        paramValue: translatedFilterValue,
        displayInFilterChips: true
      });
      found = true;
    } else {
      // keep the rest of the filter queries unchanged
      newFilterQueries.push(filterQuery);
    }
  });

  // if the filter param doesn't already exist, add it to the rest of the filter queries
  if (!found) {
    newFilterQueries.push({
      queryParam: translatedFilterParam,
      paramValue: translatedFilterValue,
      displayInFilterChips: true
    });
  }

  return newFilterQueries;
};

/**
 * Use this method when the query string needs to be updated due to a query param being updated, removed or added.
 * Notably, this does not reload the page when used; it just adds to page history and updates the URL.
 *
 * Function parameters:
 *  newQueryKey: the key of the key/value pair in the query string (ex. given a query string of q=puppies, q is the queryParam)
 *  newQueryValue: the value of the key/value pair in the query string (ex. given a query string of q=puppies, puppies is the paramValue)
 * */
export const updateQueryStringNoReload = (newQueryKey: string, newQueryValue: string) => {
  const currentURL: string = window.location.href;
  const url = new URL(currentURL);
  const currentQueryKey = url.searchParams.get(newQueryKey);

  // if an empty string is passed in, then we want to delete the query param
  // else if there is already an entry for this query param, we want to update its value
  // or we want to add a query param and its value
  if (newQueryValue == '') {
    url.searchParams.delete(newQueryKey);
  } else {
    url.searchParams.set(newQueryKey, newQueryValue);
  }

  history.pushState({}, '', url.href);
};
