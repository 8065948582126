import React, { FunctionComponent, useEffect, useState } from 'react';
import { ForgeBanner, ForgeButton, ForgeIcon, ForgeScaffold } from '@tylertech/forge-react';
import { useSelector } from 'react-redux';
import { currentUserHasRight } from 'common/current_user';
import DomainRights from 'common/types/domainRights';
import FeatureFlags from 'common/feature_flags';
import I18n from 'common/i18n';
import useScreenSize from 'common/screen_size/useScreenSize';
import FacetSidebar from 'common/components/FacetSidebar';
import MobileFacetSidebarTrigger from 'common/components/FacetSidebar/MobileFacetSidebarTrigger';
import AssetSearchAutocomplete from '../assetSearchAutocomplete';
import AssetList from './components/AssetList';
import ResultsPaginator from './components/ResultsPaginator';
import SortResults from './components/SortResults';
import NoResultsPage from './components/NoResultsPage';
import FeaturedContentBanner from './components/FeaturedContentBanner';
import FeaturedContentWrapper from './components/FeaturedContentWrapper';
import FilterChips from './components/FilterChips';
import {
  fetchAssetsByParameters,
  getAssetFetchFailed,
  getAssetsAreLoading,
  getResultsTotalSize
} from './store/AssetSlice';
import {
  fetchBrowseConfig,
  getBrowseConfig,
  getBrowseConfigFilters,
  getConfigFetchFailed,
  getFederations,
  getTags,
  setFilters
} from './store/BrowseConfigSlice';
import {
  fetchCatalogConfig,
  getCatalogConfig,
  getCatalogConfigFetchFailed
} from './store/CatalogConfigSlice';
import {
  fetchCatalogLandingPageConfig,
  getCatalogLandingPageConfig
} from './store/CatalogLandingPageConfigSlice';
import { useAppDispatch } from './store/hooks';
import {
  updateQInUrl,
  updatePageNumberInUrl,
  updatePageSizeInUrl,
  updateSortGivenCatalogOrder,
  updateTagInUrl,
  getFilterQueries,
  getCustomFacets,
  getActiveFiltersCount,
  removeAllFiltersAndTagsFromUrl,
  updateFilterQueriesInUrl,
  setAllFilters,
  removeTagFromUrl,
  addFiltersFromUrlOnLoad,
  setSearchBoosts,
  setDomainsQueryParam,
  unsetDomainsQueryParam,
  setDefaultFederationFilterCname,
  addDefaultFederationFilterToQuery,
  setCustomFacets
} from './store/UrlSlice';
import {
  addChildCategoriesToListIfPresent,
  buildFacetSidebarContent,
  formatViewTypesFacetValue,
  getFeaturedContentKey,
  getParamValueInUrl,
  getSortBy,
  translateDisplayTypeFromUrlToCeteraFormat,
  translateParamToCeteraQueryParam,
  updateOrAddFilter
} from './helpers';
import { Filter } from 'accessibleBrowseFilters/types';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_OPTIONS,
  FederationInfo,
  FilterQuery,
  FilterUpdateInfo,
  ThisSiteOnlyString
} from './types';
import Markdown from 'markdown-it';

const Browse3App: FunctionComponent = () => {
  const scope = 'controls.browse.browse3';
  const dispatch = useAppDispatch();
  const catalogConfig = useSelector(getCatalogConfig);
  const catalogConfigFetchFailed = useSelector(getCatalogConfigFetchFailed);
  const browseConfig = useSelector(getBrowseConfig);
  const browseConfigFetchFailed = useSelector(getConfigFetchFailed);
  const total = useSelector(getResultsTotalSize);
  const activeFiltersCount = useSelector(getActiveFiltersCount);
  const assetsAreLoading = useSelector(getAssetsAreLoading);
  const assetFetchFailed = useSelector(getAssetFetchFailed);
  const catalogLandingPageConfig = useSelector(getCatalogLandingPageConfig);
  const urlFilters = useSelector(getFilterQueries);
  const customFacets = useSelector(getCustomFacets);
  const filters = useSelector(getBrowseConfigFilters);
  const tags = useSelector(getTags);
  const federations = useSelector(getFederations);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [defaultFederationId, setDefaultFederationId] = useState('');
  const isMobileView = useScreenSize();
  const [isChatbotOpen, setChatbotOpen] = useState(false);

  const md = new Markdown({
    linkify: true
  });

  useEffect(() => {
    dispatch(fetchCatalogConfig());
    dispatch(fetchCatalogLandingPageConfig());
    dispatch(fetchBrowseConfig());
  }, []);

  useEffect(() => {
    if (catalogConfig.configLoaded && browseConfig.browseConfigIsLoaded && !browseConfig.filtersAreSet) {
      if (catalogConfig.federation_filter.length > 0) {
        let defaultFederatedDomainCname = '';
        const matchingFederation = federations?.find((fed) => {
          return fed.value.toString() === catalogConfig.federation_filter;
        });

        setDefaultFederationId(matchingFederation?.value || '');
        defaultFederatedDomainCname = matchingFederation?.cname ?? '';
        dispatch(setDefaultFederationFilterCname(defaultFederatedDomainCname));
        dispatch(setDomainsQueryParam(defaultFederatedDomainCname));
      }

      const viewTypesFacet = catalogConfig.config.properties?.find((prop) => {
        return prop.name === 'view_types_facet';
      })?.value;
      const formattedViewTypesFacet = formatViewTypesFacetValue(viewTypesFacet);

      dispatch(setFilters(formattedViewTypesFacet));
      if (federations) {
        dispatch(setSearchBoosts(federations));
      }

      if (browseConfig.config.customFacets) {
        dispatch(setCustomFacets(browseConfig.config.customFacets));
      }
    } else if (browseConfig.filtersAreSet) {
      dispatch(setAllFilters(filters));
      applyAllFoundUrlParametersOnLoad();

      // Now that we have any filtering from the URL applied, fetch the assets
      dispatch(fetchAssetsByParameters());
    }
  }, [catalogConfig, browseConfig]);

  const applyAllFoundUrlParametersOnLoad = () => {
    /* START: This order matters! I think it has something to do with the page setting
    default values for these things, then overriding them when different values are found in the URL.
    The actions dispatched during the overriding puts things into a specific state. I think. */
    const queryInUrl = getParamValueInUrl('q') ?? '';
    const pageNumber = parsePageNumberInUrl();
    const pageSize = parsePageSizeInUrl();
    const tagInUrl = getParamValueInUrl('tags');

    const sortBy = getSortBy(catalogConfig.configQueryParams.order ?? '');

    dispatch(updateQInUrl(queryInUrl));
    dispatch(updateSortGivenCatalogOrder(sortBy));
    dispatch(updatePageNumberInUrl(pageNumber));
    dispatch(updateTagInUrl(tagInUrl));
    dispatch(updatePageSizeInUrl(pageSize));
    /* END*/

    const filterQueries = browseConfig.filters.map((f) => {
      if (f.param === 'federation_filter') {
        return generateFederationFilterQueryFromUrl(f);
      } else {
        return generateFilterQueryFromUrl(f.param);
      }
    });

    dispatch(addFiltersFromUrlOnLoad(filterQueries));
  };

  const generateFilterQueryFromUrl = (facetInUrl: string) => {
    const filtersToApply: FilterQuery[] = [];
    const ceteraParam = translateParamToCeteraQueryParam(facetInUrl);
    let valueOfFacetInUrl = getParamValueInUrl(facetInUrl);
    if (valueOfFacetInUrl) {
      if (facetInUrl === 'limitTo') {
        valueOfFacetInUrl = translateDisplayTypeFromUrlToCeteraFormat(valueOfFacetInUrl);
      }

      if (facetInUrl === 'category') {
        const categoryFilterIndex = filters.findIndex((filter) => {
          return filter.param === facetInUrl;
        });

        if (categoryFilterIndex !== -1) {
          addChildCategoriesToListIfPresent(
            filters[categoryFilterIndex].options,
            valueOfFacetInUrl,
            false,
            filtersToApply,
            ceteraParam
          );
        }
      }

      filtersToApply.push({
        queryParam: ceteraParam,
        paramValue: valueOfFacetInUrl
      });
    }
    return filtersToApply;
  };

  const generateFederationFilterQueryFromUrl = (federationFilter: Filter) => {
    const federationToApply: FilterQuery[] = [];
    const domainIdInUrl = getParamValueInUrl('federation_filter');
    const currentFederation = federationFilter.options.find((option) => option.value === domainIdInUrl);
    if (currentFederation) {
      let domainCname = currentFederation.text;
      if (domainCname === ThisSiteOnlyString) {
        const thisFederation = federations?.find((fed) => {
          return fed.text === ThisSiteOnlyString;
        });
        if (thisFederation) {
          domainCname = thisFederation.cname;
        }
      }

      federationToApply.push({
        queryParam: 'domains',
        paramValue: domainCname
      });
    }
    return federationToApply;
  };

  const parsePageNumberInUrl = (): number => {
    const pageInUrl = getParamValueInUrl('page');
    return pageInUrl ? parseInt(pageInUrl) : 1;
  };

  // If the pageSize value is not one of the pageSize options, change it to the default.
  const parsePageSizeInUrl = (): number => {
    const pageSizeInUrl = getParamValueInUrl('pageSize');
    const pageSize = pageSizeInUrl ? parseInt(pageSizeInUrl) : DEFAULT_PAGE_SIZE;
    return DEFAULT_PAGE_SIZE_OPTIONS.includes(pageSize) ? pageSize : DEFAULT_PAGE_SIZE;
  };

  const getTitle = (): string => {
    if (!urlFilters) return I18n.t('catalog', { scope });
    const key = getFeaturedContentKey(urlFilters, customFacets);
    const clpConfig = catalogLandingPageConfig.results.find((config) => config.name === key);
    if (clpConfig && clpConfig.headline) return clpConfig.headline;
    return I18n.t('catalog', { scope });
  };

  const getDescriptionSection = () => {
    if (!urlFilters) return null;
    const key = getFeaturedContentKey(urlFilters, customFacets);
    const clpConfig = catalogLandingPageConfig.results.find((config) => config.name === key);
    if (clpConfig && clpConfig.description) {
      const formattedDescription = {
        __html: md.render(clpConfig.description)
      };
      return <div className="catalog-description" dangerouslySetInnerHTML={formattedDescription} />;
    }
    return null;
  };

  const hideDrawer = () => {
    setIsDrawerOpen(false);
  };

  const showDrawer = () => {
    setIsDrawerOpen(true);
  };

  const catalogTitleClass = isMobileView
    ? 'forge-typography--headline4 title-for-mobile'
    : 'forge-typography--headline4';

  const onClearAllFilters = () => {
    dispatch(removeAllFiltersAndTagsFromUrl());
    dispatch(fetchAssetsByParameters());
    hideDrawer();
  };

  const onFilterSelect = (filterParam: string, filterValue: string) => {
    let newFilterQueries: FilterQuery[] = [];
    let federationCname = '';
    let filterValueForUrl = '';
    const translatedFilterParam = translateParamToCeteraQueryParam(filterParam);
    let matchingFederation: FederationInfo | undefined;

    // for federations, translatedFilterValue needs to be cname
    if (filterParam === 'federation_filter') {
      filterValueForUrl = filterValue; // filterValue comes in as domain ID for federations
      matchingFederation = federations?.find((fed) => {
        return fed.value.toString() === filterValue;
      });
      federationCname = matchingFederation?.cname ?? '';
      dispatch(unsetDomainsQueryParam());
    }

    const translatedFilterValue =
      filterParam === 'federation_filter'
        ? federationCname
        : translateDisplayTypeFromUrlToCeteraFormat(filterValue);

    if (urlFilters && urlFilters.length != 0) {
      newFilterQueries = updateOrAddFilter(urlFilters, translatedFilterParam, translatedFilterValue);
    } else if (translatedFilterParam) {
      newFilterQueries.push({
        queryParam: translatedFilterParam,
        paramValue: translatedFilterValue,
        displayInFilterChips: true
      });
    }

    /** If a parent category is selected, then we also need to include its children in the query
     * (if there are any). */
    if (filterParam === 'category') {
      const categoryFilterIndex = filters.findIndex((filter) => {
        return filter.param === filterParam;
      });

      if (categoryFilterIndex !== -1) {
        addChildCategoriesToListIfPresent(
          filters[categoryFilterIndex].options,
          filterValue,
          false,
          newFilterQueries,
          translatedFilterParam
        );
      }
    }

    const filterUpdateInfo: FilterUpdateInfo = {
      urlParts: {
        filterParam: filterParam,
        filterValue: filterValue,
        filterValueForUrl: filterValueForUrl
      },
      filters: newFilterQueries
    };

    dispatch(updateFilterQueriesInUrl(filterUpdateInfo));
    dispatch(fetchAssetsByParameters());
  };

  const onFilterClear = (filterParam: string) => {
    dispatch(updatePageNumberInUrl(1)); // always reset to first page of results with new filter applied

    const translatedFilterParam = translateParamToCeteraQueryParam(filterParam);
    const newFilterQueries: FilterQuery[] = [];
    // keep other existing filters
    urlFilters?.forEach((filterQuery) => {
      if (filterQuery.queryParam !== translatedFilterParam) {
        newFilterQueries.push(filterQuery);
      }
    });

    const filterUpdateInfo: FilterUpdateInfo = {
      urlParts: {
        filterParam: filterParam,
        filterValue: ''
      },
      filters: newFilterQueries
    };

    // replace default federation filter if necessary
    if (filterParam === 'federation_filter' && catalogConfig.federation_filter.length > 0) {
      dispatch(addDefaultFederationFilterToQuery());
    }

    dispatch(updateFilterQueriesInUrl(filterUpdateInfo));
    dispatch(fetchAssetsByParameters());
  };

  const onTagSelect = (tagValue: string) => {
    dispatch(updateTagInUrl(tagValue));
    dispatch(updatePageNumberInUrl(1)); // always reset to first page of results with new filter applied
    dispatch(fetchAssetsByParameters());
  };

  const onTagClear = () => {
    dispatch(removeTagFromUrl());
    dispatch(updatePageNumberInUrl(1)); // always reset to first page of results with new filter applied
    dispatch(fetchAssetsByParameters());
  };

  const generateFacetSidebarContent = () => {
    return buildFacetSidebarContent(
      filters,
      onFilterSelect,
      onFilterClear,
      tags,
      onTagSelect,
      onTagClear,
      defaultFederationId
    );
  };

  const reloadClick = () => {
    location.reload();
  };

  const renderChatbot = () => {
    setChatbotOpen(!isChatbotOpen);
  };

  const renderAIButton = () => {
    if (FeatureFlags.value('enable_ai_chatbot')) {
      return (
        <ForgeButton type="raised" data-testid="render-ai-button">
          <button onClick={renderChatbot}>Click for AI!</button>
        </ForgeButton>
      );
    } else {
      return null;
    }
  };

  const renderAiChatbotContainer = () => {
    if (FeatureFlags.value('enable_ai_chatbot') && isChatbotOpen) {
      return (
        <div
          slot="right"
          data-testid="ai-chatbot-container"
          id="ai-chatbot-container"
          className="browse3-AI-chatbot"
        >
          <div></div>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderServerError = () => {
    return assetFetchFailed || browseConfigFetchFailed || catalogConfigFetchFailed;
  };

  return (
    <div>
      {renderServerError() ? (
        <div className="there-was-a-problem">
          <img src="https://cdn.forge.tylertech.com/v1/images/spot-hero/500-error-spot-hero.svg" />
          <h1 className="forge-typography--headline4">{I18n.t('oops', { scope })}</h1>
          <div className="help-text">
            <p className="forge-typography--body1">
              {I18n.t('server_error_1', { scope })} <br />
              {I18n.t('server_error_2', { scope })}
            </p>
          </div>
          <ForgeButton type="raised">
            <button onClick={reloadClick}>{I18n.t('refresh', { scope })}</button>
          </ForgeButton>
        </div>
      ) : (
        <ForgeScaffold>
          <FacetSidebar
            title={I18n.t('filter.filters', { scope })}
            hasMobileView={true}
            isMobileDrawerOpen={isDrawerOpen}
            onMobileDrawerClose={hideDrawer}
            onMobileClearAllFilters={onClearAllFilters}
            children={generateFacetSidebarContent()}
          />
          <div slot={'header'} className="featured-content-banner">
            <div className="browse3-preview-banner">
              {FeatureFlags.value('browse_preview_banner') && (
                <ForgeBanner className="forge-typography--body1">
                  <div>
                    {I18n.t('browse3_tech_preview_banner', { scope })}
                    <ForgeButton type="outlined">
                      <button
                        type="button"
                        onClick={() => {
                          window.location.href = window.location.origin + '/browse';
                        }}
                      >
                        <ForgeIcon name="swap_horiz"></ForgeIcon>
                        <span>{I18n.t('browse3_tech_preview_button', { scope })}</span>
                      </button>
                    </ForgeButton>
                  </div>
                </ForgeBanner>
              )}
            </div>
            {currentUserHasRight(DomainRights.feature_items) && <FeaturedContentBanner />}
          </div>
          <div slot="body-header" className="browse3-header">
            <div className="catalog-header">
              <h1 className={catalogTitleClass}>{getTitle()}</h1>
              <AssetSearchAutocomplete />
              {renderAIButton()}
            </div>
            {getDescriptionSection()}
            <div className="catalog-results">
              <div className="results-left-section">
                <div className="forge-typography--body1">
                  <strong>{total}</strong>{' '}
                  {total === 1 ? I18n.t('result', { scope }) : I18n.t('results', { scope })}
                </div>
                <MobileFacetSidebarTrigger
                  activeFacetsCount={activeFiltersCount}
                  onMobileDrawerOpen={showDrawer}
                />
                {!isMobileView && <FilterChips />}
              </div>
              <div className="sort-results">
                <SortResults />
              </div>
            </div>
            <FeaturedContentWrapper />
          </div>
          <div slot="body" className="browse3-body">
            <div className="body-with-cards-and-paginator">
              {total === 0 && !assetsAreLoading ? (
                <NoResultsPage />
              ) : (
                <div>
                  <AssetList />
                  <ResultsPaginator />
                </div>
              )}
            </div>
          </div>
          {renderAiChatbotContainer()}
        </ForgeScaffold>
      )}
    </div>
  );
};

export default Browse3App;
