import React, { FunctionComponent, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { getCustomFacets, getFilterQueries, getTextQuery } from 'browse3/store/UrlSlice';
import './FeaturedContentBanner.scss';
import { ForgeBanner, ForgeButton } from '@tylertech/forge-react';
import {
  encodeFeaturedContentPath,
  translateCeteraQueryParamToUrlParam,
  translateDisplayTypeFromCeteraToUrlFormat
} from 'browse3/helpers';
import I18n from 'common/i18n';

const FeaturedContentBanner: FunctionComponent = () => {
  const PARAMS_ALLOWLIST = ['category', 'limitTo', 'provenance', 'federation_filter'];
  const t = (k: string) => I18n.t(k, { scope: 'controls.browse.browse3.featured_content.banner' });
  const urlFilters = useSelector(getFilterQueries);
  const customFacets = useSelector(getCustomFacets);
  const searchFilter = useSelector(getTextQuery);

  if (searchFilter) {
    return <></>;
  }

  const navigateToCatalogLandingPage = () => {
    let queryParams = '';

    if (urlFilters) {
      for (const filter of urlFilters) {
        const translatedParam = translateCeteraQueryParamToUrlParam(filter.queryParam);

        if (
          PARAMS_ALLOWLIST.includes(translatedParam) ||
          customFacets
            .map((facet) => {
              return facet.param;
            })
            .includes(translatedParam)
        ) {
          if (queryParams.length > 0) {
            queryParams += '&';
          }
          if (translatedParam === 'limitTo') {
            queryParams += `limitTo=${translateDisplayTypeFromCeteraToUrlFormat(filter.paramValue)}`;
          } else {
            const paramValue = encodeFeaturedContentPath(filter.paramValue);
            queryParams += `${translatedParam}=${paramValue}`;
          }
        }
      }
    }

    if (!queryParams) {
      queryParams = 'custom_path=%2Fbrowse';
    }

    window.location.href =
      window.location.origin + '/catalog_landing_page/manage?' + queryParams.replace(/ /g, '+');
  };

  const banner: ReactElement =
    urlFilters == null || urlFilters.length < 2 ? (
      <ForgeBanner canDismiss={false} className="featured-content-banner forge-typography--body1">
        <div>{t('label')}</div>
        <ForgeButton slot="button" type="outlined">
          <button
            data-testid="featured-content-banner-button"
            type="button"
            onClick={navigateToCatalogLandingPage}
          >
            {t('button')}
          </button>
        </ForgeButton>
      </ForgeBanner>
    ) : (
      <></>
    );

  return <>{banner}</>;
};

export default FeaturedContentBanner;
